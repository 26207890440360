import request from '@/utils/axios';

// 登录修改手机号操作
export const login = (row,val) => {
    return request({
        url: '/blade-user/bingEdit',
        method: 'post',
        headers: {
                'Captcha-Key': val.key,
                'Captcha-Code': val.code,
            },
        data: row,
    })
}

// 微信授权获取code
export const getAppCode = (row) => {
    return request({
        url: '/blade-auth/wxToken',
        method: 'post',
        data: row
    })
}

// 获取验证码
export const getCaptcha = () => {
    return request({
        url: '/blade-auth/captcha',
        method: 'get',
    })
}

// 核销操作
export const getCodeList = (params) => {
    return request({
        url: '/verificationcode/list',
        method: 'get',
        params: {
            ...params,
        }
    })
}

// 获取用户收货地址
export const getAddressList = (params) => {
    return request({
        url: '/useraddress/list',
        method: 'get',
        params: {
            ...params,
        }
    })
}

// 获取省市区
export const getMapList = (params) => {
    return request({
        url: '/blade-system/region/lazy-list',
        method: 'get',
        params: {
            ...params,
        }
    })
}


// 获取核销码详情
export const getCodeDetail = (params) => {
    return request({
        url: '/verificationcode/detail',
        method: 'get',
        params: {
            ...params,
        }
    })
}

// 新增收货地址
export const addressSave = (row) => {
    return request({
        url: '/useraddress/save',
        method: 'post',
        data: row
    })
}

// 修改收货地址
export const addressEdit = (row) => {
    return request({
        url: '/useraddress/update',
        method: 'post',
        data: row
    })
}

// 删除收货地址
export const addressDel = (ids) => {
    return request({
        url: '/useraddress/remove',
        method: 'post',
        params: {
            ids,
          }
    })
}

// 获取收货地址详情
export const getAddressInfo = (params) => {
    return request({
        url: '/useraddress/detail',
        method: 'get',
        params: {
            ...params,
        }
    })
}

// 核销
export const addOrder = (row) => {
    return request({
        url: '/orderform/submit',
        method: 'post',
        data: row
    })
}

// 核销订单表
export const orderList = (params) => {
    return request({
        url: '/orderform/listUser',
        method: 'get',
        params: {
            ...params,
        }
    })
}

// 核销绑定用户
export const codeSubmit = (row) => {
    return request({
        url: '/verificationcode/submit',
        method: 'post',
        data: row
    })
}

// 首页商品列表
export const productList = (params) => {
    return request({
        url: '/commodity/commodity/olList',
        method: 'get',
        params: {
            ...params,
        }
    })
}

// 商品详情
export const productInfo = (params) => {
    return request({
        url: '/commodity/commodity/detail',
        method: 'get',
        params: {
            ...params,
        }
    })
}

// 首页轮播
export const swiperList = () => {
    return request({
        url: '/slideshow/listAll',
        method: 'get',
    })
}

// 获取微信签名
export const getSing = (params) => {
    return request({
        url: '/wxShare/getSing',
        method: 'get',
        params: {
            ...params
        }
    })
}

// 添加留言
export const addMessage = (row) => {
    return request({
        url: '/comments/submit',
        method: 'post',
        data: row
    })
}

// 文件上传
export const putFile = (row) => {
    return request({
      url: '/blade-resource/oss/endpoint/put-file',
      method: 'post',
      data: row
    })
  }

  // 活动列表
export const getActivity = (params) => {
    return request({
        url: '/activity/olList',
        method: 'get',
        params: {
            ...params,
        }
    })
}

// 活动是否开启
export const getOpen = () => {
    return request({
      url: '/activity/activityOpen',
      method: 'get',
    })
  }

  // 活动详情
  export const activityInfo = (id) => {
    return request({
        url: '/activity/detail',
        method: 'get',
        params: {
            id,
        }
    })
}
