import Vue from 'vue'
import App from './App.vue'
import './assets/global.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import router from '@/router/index.js'
import Nav from '@/globalComponents/Nav'
import Banner from '@/globalComponents/Banner'
import '@/assets/icon/iconfont.css'
import SearchInput from '@/globalComponents/SearchInput'
import OrderAndUserList from '@/globalComponents/OrderAndUserList'
import PageNation from '@/globalComponents/Pager'
import './utils/rem.js';
import './utils/vants.js'
import { getAppCode } from "@/api/index.js";
import { setToken, getToken } from '@/utils/auth'
import { GetParam } from "@/utils/util";
Vue.component('PageNation', PageNation)
Vue.component('SearchInput', SearchInput)
Vue.component('OrderAndUserList', OrderAndUserList)
Vue.component("Nav", Nav)
Vue.component("Banner", Banner)
Vue.config.productionTip = false
Vue.use(ElementUI)
router.beforeEach((to, from, next) => {
  // if(to.path === '/error'){
  //   next()
  //   return
  // }
  let userInfo = JSON.parse(localStorage.getItem('userInfo'))
  let token = localStorage.getItem('token');
  let appid = "wx33fabda7806e4867"; //实际公众号appid，这里的appid对应的微信应用一定是绑定当前h5项目所在线上域名，也就是需要在微信开放平台绑定js安全域名
  let redirect_uri = encodeURIComponent(
    'https://youwu.reportauto.cn'
  );
  console.log(to.path);
  if (!token) {
    let url =
      "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
      appid +"&redirect_uri=" + redirect_uri +"&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect";
// 以snsapi_base为scope发起的网页授权，是用来获取进入页面的用户的openid的，并且是静默授权并自动跳转到回调页的.（静默的另一种：对于已关注公众号的用户，如果用户从公众号的会话或者自定义菜单进入本公众号的网页授权页，即使是scope为snsapi_userinfo，也是静默授权，用户无感知。）以snsapi_userinfo为scope发起的网页授权，是用来获取用户的基本信息的。但这种授权需要用户手动同意，并且由于用户同意过，所以无须关注，就可在授权后获取该用户的基本信息。
    console.log("window.location.href", window.location.href);
    let code = GetParam(window.location.href.split("#")[0], "code");
    if (!code) {
      window.location.href = url;
    } else {
      code = GetParam(window.location.href, "code");
      let data = new FormData();
      data.append('code', code)
// 这里则是根据授权登录后回调到当前h5地址，从当前地址导航栏拿取code,根据实际开发的应用场景，比如向我们项目则是调用一个有后台提供的获取微信个人信息的接口，这个接口是后台根据拿取到的code去请求微信官方文档获取个人信息的接口，下面是我根据我实际业务场景编写的，主要是根据code获取个人信息保存。授权登录走到这一步基本就做完了
        getAppCode(data).then(res => {
        if (res.code == 200) {
          localStorage.setItem('token', res.data.accessToken)
          setToken(res.data.accessToken)
          localStorage.setItem('userInfo', JSON.stringify(res.data))
          if(res.data.bingPhone == 0){
            console.log('初次登陆',res.data)
            next('/login')
          }else{
            console.log('二次登陆',res.data)
            next('/home')
          }
          next();
        }else{
          window.location.href = url
        }
        // if (res.success) {
        //   console.log(res.result,'rrr');
        //   store.commit("user/SET_TONKEN", res.result.token);
        //   store.commit("user/SET_USERINFO", res.result);
        //   server.user.getMsgNumByUser().then(res => {
        //     store.commit("user/setHasLatest", res.result.latest);
        //   });
        //   next();
        // } else {
        //   if(res.error.code == '3408' || res.error.code == '3415'){
        //     console.log(res.error)
        //   }
        //   else {
        //     window.location.href = url // 如果请求失败继续走重定向去获取code这一步
        //   }
        // }
      });
      // next();
    }
  } else {
    if(userInfo.bingPhone == 0 && to.path !== '/login' && to.path !== '/privacy'){
      console.log('没绑定')
      next('/login');
    }else{
      next();
    }
  }
});
new Vue({
  router,
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this
  }
}).$mount('#app')
